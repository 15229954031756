import { MotionImage } from "components/MotionImage";
import { Page } from "components/Page";
import TicketShop from "components/ticket-shop";
import { Link } from "gatsby";
import React from "react";

export function BlogPage() {
  return (
    <Page>
      <div className="container max-w-2xl mx-auto">
        <section className="py-8">
          <div className="text-md mb-2">20 September 2022</div>
          <div>
            <h1 className="text-4xl mb-4">Fresh2Death events unveiled!</h1>
            <p>
              After a summer of holidays, festivals and event planning we return
              for the new academic year with a portfolio of incredible events;
              some new faces and others you all know &amp; love. We've
              maintained an extremely important ethos of ours, doing our utmost
              to work with and support independent venues across Leeds, who are
              incidentally also some of the best event spaces in the city.
            </p>
            <p>
              <figure>
                <MotionImage
                  className="w-full mx-auto shadow-xl object-cover "
                  style={{ minHeight: 350, aspectRatio: 61 / 32 }}
                  src="/events/bobbys.jpg"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                />
                <figcaption>Bobby's Disco Club</figcaption>
              </figure>
            </p>
            <p>
              Returning to his weekly Monday night slot at The Warehouse is{" "}
              <Link to="/tickets/bobbys-disco-club">Bobby's Disco Club</Link>{" "}
              with a huge collection of fabulous Disco songs &amp; great vibes.
              Bobby is entering his 5th year of throwing Disco driven parties at
              venues across Leeds. Not only does he invite you to his Disco Club
              every week, he also throws huge Disco Shows and wild Disco
              Festivals on key dates for you all to enjoy. Proudly hosting one
              of the most diverse student dancefloors in the city, everyone is
              welcome to enjoy his Disco tunes &amp; confetti showers.
            </p>
            <p>
              <figure>
                <MotionImage
                  className="w-full mx-auto shadow-xl object-cover "
                  style={{ minHeight: 350, aspectRatio: 61 / 32 }}
                  src="/events/mixtape-project.jpg"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                />
                <figcaption>Mixtape Project</figcaption>
              </figure>
            </p>
            <p>
              Our longest running brand is about to celebrate 15 year magical
              years of shindigs in Leeds.{" "}
              <Link to="/tickets/mixtape-project">Mixtape Project</Link> is the
              most unique music driven student event in the city boasting a
              can't be categorised music policy. Find yourselves boppin' to
              Aretha Franklin one minute then ravin' away to Shy FX the next.
              Every Tuesday, Mixtape will be at HiFi Club, one of the cities
              most popular clubs, throwing sell out basement boogies with a
              awesome crowd of people.
            </p>
            <p>
              New to the Fresh2Death event roster is our weekly indoor mini
              festival. Full Fat Thursdays lands at the sickest student rave
              space, The Old Red Bus Station, taking over all 3 rooms of the
              venue. House, tech &amp; disco in one, DNB &amp; bass in another
              and house party vibes in the 3rd. Full Fat has curated a line up
              of weekly residents, guest DJs and rotating brands to ensure ORBS
              is bouncing every single Thursday night. This is the only regular
              student event in the venue so you know it's going to be special.
              The creme de la creme of student raving!
            </p>
            <p>
              <figure>
                <MotionImage
                  className="w-full mx-auto shadow-xl object-cover "
                  style={{ minHeight: 350, aspectRatio: 61 / 32 }}
                  src="/events/better-days-festival.jpg"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                />
                <figcaption>Better days festival</figcaption>
              </figure>
            </p>
            <p>
              Alongside our 3 weekly events, we also have a series of one offs,
              ranging from 350 capacity funky grooves in the form of Deep Fried
              Funk, up to 3000 capacity all day outdoor festivals courtesy of{" "}
              <Link to="/special-events/better-days-festival">
                Better Days Festival
              </Link>
              . The former has been running for over 3 years now and calls (the
              absolutely fabulous) Sheaf St ‘home'.
            </p>

            <p>
              <figure>
                <MotionImage
                  className="w-full mx-auto shadow-xl object-cover "
                  style={{ minHeight: 350, aspectRatio: 61 / 32 }}
                  src="/events/deep-fried-funk.jpg"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                />
                <figcaption>Deep Fried Funk</figcaption>
              </figure>
            </p>
            <p>
              Specialising in funk, disco and groovy house,{" "}
              <Link to="/tickets/deep-fried-funk">Deep Fried Funk</Link>{" "}
              provides a welcoming dance floor full of big smiles and feel good
              sounds. With 3 key dates in term 1, you can get your funky fix in
              Freshers, at Halloween or at the End of Term, where you'll hear
              plenty of bootylicious tunes to get you grooving. Better Days
              Festival was launched back in May and has since thrown 3 sell out
              festivals across the entire Beaver Works Complex. The Festival has
              brought something entirely new to Leeds and has been attended by
              over 7000 people already. We are extremely proud of this one and
              are busy at work on new plans for the brand, so keep those eyes
              peeled.
            </p>
            <p>
              One that we're extremely excited about is our new project,{" "}
              <Link to="/special-events/the-secret-social">
                The Secret Social
              </Link>
              . We take coaches full of students &amp; young people from Leeds
              to a secret city for a night of bar crawls, partying &amp; good
              times, then bring you all back at the end of the night. We
              trialled this with a series of private society groups and it ended
              up being an incredible experience for everyone… so much so, that
              we decided to open it up to everyone this year. If you fancy going
              and partying in a city with all your mates and not finding out
              where you're going until you arrive, then this one is for you!
              Your ticket price includes return travel, tunes on the coach,
              entry to bars, queue jump entry to a nightclub and some drinks!
            </p>
            <p>
              Last, but most certainly not least, is the return of our crazy
              good event It's Coming Home! Launched last year as a Euro 2021
              viewing party, the event became the most popular place in Leeds to
              watch England make it all the way to the Final. We had multiple
              huge screens, big long tables, steins &amp; kegs of beer, DJs,
              after parties, singalongs, food &amp; so much more. We are
              returning to the Beaver Works Complex across November and December
              showing all the England World Cup Games! We have 2 giant screens,
              1 in a heated marquee with seating and the other in an indoor 1000
              capacity standing room, both with mind blowing atmospheres and DJs
              hosting. You won't want to watch the games anywhere else!
            </p>
            <p>
              If you like the sound of what we do, and would like to join our
              team, please visit our{" "}
              <Link to="/student-jobs">Student Jobs</Link> section on our
              website and get in touch. We organise awesome staff socials and
              nights out plus you get free entry to all our events and
              festivals.
            </p>
            <p>
              We look forward to seeing you at a Fresh2Death event soon. F2D x
            </p>
          </div>
        </section>
      </div>
    </Page>
  );
}

export default BlogPage;
